<template src="./NewAdvertising.html">

</template>

<script>
import UploadImageModal from "@/components/UploadImageModal/UploadImageModal";
import coursesService from "@/services/Courses";
import advertisingService from "@/services/Advertising";

export default {
  name: "NewAdvertising",
  components: {
    UploadImageModal
  },
  data() {
    return {
      file: null,
      isActive: false,
      infoFile: {},
      title: null,
      description: null,
      type: null,
      events: [],
      advertisingType: null,
      elementId: 'elementId'
    }
  },
  methods: {
    async createAdvertising() {
      try {
        this.$store.dispatch('fetchLoading', true);
        const imageLink = await coursesService.uploadFile('imagesAdvertising', 'imagesAdvertising', this.infoFile.name, this.infoFile.file);
        const info = {
          title: this.title,
          description: this.description,
          type: this.advertisingType,
          elementId: this.elementId,
          image: imageLink,
          imageDescription: this.infoFile.description,
        }
        await advertisingService.createAdvertising(info);
        this.$router.push({ name: "Profile" });
        this.$buefy.toast.open({
          duration: 5000,
          message: "Publicidad creada correctamente",
          position: "is-bottom",
          type: "is-success"
        });
      } catch (e) {
        console.log('e', e);
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: "Error creando publicidad!",
          type: "is-error",
        });
        this.$store.dispatch('fetchLoading', false);
      }
    },
    async getOptionsToShow() {
      try {
        if (this.advertisingType === 'event') this.events = await advertisingService.getLastEvents();
        else if (this.advertisingType === 'announcement') this.events = (await advertisingService.getAnnouncementList()).jobOffers;
      } catch (e) {
        console.log('e', e);
      }
    }
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss" src="./NewAdvertising.scss">

</style>
